import { useTranslation } from "next-i18next";
import { useMutation, UseMutationOptions } from "react-query";
import { message } from "antd";
import { createProject } from "../api/project.api";
import { CreateProjectReturn, CreateProjectInput } from "../model/actions/project-create.model";
import { Project } from "../model/project.model";

export const useCreateProjectMutation = (
  options: UseMutationOptions<Project, null, CreateProjectInput> = {}
) => {
  const { t } = useTranslation(["project"]);

  return useMutation<Project, null, CreateProjectInput>(createProject, {
    ...options,
    onSuccess: (data, variables, context) => {
      message.success(t("messages.successfullyCreated"))

      if (options.onSuccess) {
        options.onSuccess(data, variables, context)
      }
    },
    onError: async (error, variables, context) => {
      console.log(error);
      message.error(t("messages.updateUnsuccessful"));

      if (options.onError) {
        options.onError(error, variables, context)
      }
    },
  });
};
;
