import { gql } from "graphql-tag";
import { s3FileFragment } from "./s3-file.fragment";

// Mutation to create s3File
export const CreateS3FileMutation = gql`
	${s3FileFragment}
	mutation createS3FileMutation($input: createS3FileInput) {
		createS3File(input: $input) {
			s3File {
				...s3FileFragment
			}
		}
	}
`;

// Mutation to update s3File
export const UpdateS3FileMutation = gql`
	${s3FileFragment}
	mutation updateS3FileMutation($input: updateS3FileInput) {
		updateS3File(input: $input) {
			s3File {
				...s3FileFragment
			}
		}
	}
`;

// Mutation to delete s3File
export const DeleteS3FileMutation = gql`
	${s3FileFragment}
	mutation deleteS3FileMutation($input: deleteS3FileInput) {
		deleteS3File(input: $input) {
			s3File {
				...s3FileFragment
			}
		}
	}
`;
