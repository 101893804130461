import { FormInstance, ModalForm, ModalFormProps } from "@ant-design/pro-form";
import { useTranslation } from "next-i18next";
import React from "react";
import { CreateProjectInputData } from "../../../model/actions/project-create.model";
import { ProjectFormFields } from "../fields/fields";

export interface ProjectModalFormProps extends ModalFormProps {
  className?: string;
  fieldsAreReadOnly?: boolean;
  form: FormInstance;
}

export const ProjectModalForm = (props: ProjectModalFormProps) => {
  const { className = "", fieldsAreReadOnly = false, form } = props;
  const { t } = useTranslation(["company-partner", "common"]);

  return (
    <ModalForm<CreateProjectInputData> width={700} {...props}>
      <ProjectFormFields fieldsAreReadOnly={fieldsAreReadOnly} form={form} />
    </ModalForm>
  );
};
