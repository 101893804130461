import { EditApprovalStepInput } from "@/lib/sdk/sdk";
import { CloseOutlined } from "@ant-design/icons";
import { ModalForm, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { Button, ButtonProps, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export interface RejectDocumentModalProps {
  className?: string;
  buttonProps?: ButtonProps;
  onFinish: (values: EditApprovalStepInput) => Promise<boolean>;
}

export const RejectDocumentModal = (props: RejectDocumentModalProps) => {
  const { className = "", onFinish, buttonProps } = props;
  const { t } = useTranslation(["common"]);

  return (
    <ModalForm<EditApprovalStepInput>
      title="Zamietnuť faktúru"
      width={600}
      trigger={
        <Button icon={<CloseOutlined />} type="default" danger {...buttonProps}>
          Zamietnuť
        </Button>
      }
      modalProps={{}}
      onFinish={onFinish}
      submitter={{
        submitButtonProps: {
          icon: <CloseOutlined />,
          danger: true,
        },
        searchConfig: {
          submitText: "Zamietnuť",
          resetText: "Zrušiť",
        },
      }}
    >
      <Typography.Paragraph>
        Týmto potvrdzujete, že sa rozhodujete zamietnuť faktúru na základe
        príčiny uvedenej nižšie.
      </Typography.Paragraph>

      <ProFormText
        rules={[
          {
            required: true,
            message: t("common:form.errors.required"),
          },
        ]}
        name="reason"
        label="Dôvod"
        tooltip="Dôvod prečo ste sa rozhodli danú faktúru zamietnuť."
        placeholder="Zlá suma a pod."
      />

      <ProFormTextArea
        name="note"
        label="Poznámka"
        tooltip="Poznámka k schválení faktúry."
        placeholder=""
      />
    </ModalForm>
  );
};
