import { IrisSpin } from "@/components/Shared/IrisSpin/IrisSpin";
import { AbilityContext } from "@/components/Shared/utils/CaslProvider/CaslProvider";
import { useProjectCollectionQuery } from "@/lib/features/projects/hooks/useProjectCollectionQuery.hook";
import { useSingleProjectQuery } from "@/lib/features/projects/hooks/useSingleProjectQuery.hook";
import { Project } from "@/lib/features/projects/model/project.model";
import { useRelationshipSelectOptionsData } from "@/lib/shared/hooks/form/useRelationshipSelectOptionsData";
import { PlusOutlined } from "@ant-design/icons";
import { ProFormSelect } from "@ant-design/pro-form";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { useAbility } from "@casl/react";
import { Button, Empty, FormInstance } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddProjectModalForm } from "../../../ProjectForm/AddProjectModalForm/AddProjectModalForm";

export interface SingleProjectSelectProps extends ProFormSelectProps {
  name: string;
  form?: FormInstance;
}

/** */
export const SingleProjectSelect = (props: SingleProjectSelectProps) => {
  const { form } = props;
  const { t } = useTranslation(["common"]);
  const ability = useAbility(AbilityContext);
  const [hasBeenActivated, setHasBeenActivated] = useState(false);

  const { data: initialValueData } = useSingleProjectQuery(
    form?.getFieldValue(props.name),
    {}
  );

  const { data, setFilters, isFetching } = useProjectCollectionQuery({
    queryOptions: {
      enabled: hasBeenActivated,
    },
  });

  const optionsData = useRelationshipSelectOptionsData<Project>(
    data?.projects,
    initialValueData
  );

  return (
    <ProFormSelect
      showSearch
      fieldProps={{
        onMouseEnter: () => setHasBeenActivated(true),
        onSearch: (keyword) => {
          setFilters({
            name_contains: keyword,
          });
        },
        loading: isFetching,
        dropdownRender: (menu) => {
          if (isFetching) {
            return (
              <div className="flex justify-center h-12 items-center">
                <IrisSpin />
              </div>
            );
          }
          return (
            <div>
              {!!data && data?.projects.length > 0 ? (
                <>{menu}</>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              <AddProjectModalForm
                onProjectCreated={(project) => {
                  form.setFieldsValue({
                    project: project.id,
                  });
                }}
                modalProps={{
                  zIndex: 1000,
                }}
                submitter={{
                  searchConfig: {
                    submitText: t("common:actions.add"),
                    resetText: t("common:actions.cancel"),
                  },
                }}
                trigger={
                  <Button
                    icon={<PlusOutlined />}
                    type="link"
                    hidden={ability.cannot("create", "project")}
                    className="flex w-full text-left justify-start"
                  >
                    Pridať zákazku
                  </Button>
                }
              />
            </div>
          );
        },
      }}
      options={optionsData.map((option) => ({
        label: option.name,
        value: option.id,
      }))}
      {...props}
    />
  );
};
