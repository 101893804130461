import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { useBreakpointValue } from "@chakra-ui/react";
import { Button, Modal, Space } from "antd";
import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Document, Page } from "react-pdf";
import { SizeMe } from "react-sizeme";

export interface PdfViewerProps {
  pdf: any;
  onCancel: any;
  visible: boolean;
}

export const PdfViewer = ({ pdf, onCancel, visible }) => {
  const [myPdf, setMyPdf] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [scale, setScale] = useState(1);

  const onDocumentComplete = (numPages) => {
    setPages(numPages);
  };

  const onDocumentError = (err) => {
    console.error("pdf viewer error:", err);
  };

  const onSetScale = (type) => {
    var newScale = type ? scale + 0.1 : scale - 0.1;

    if (newScale > 2) {
      newScale = 2;
    } else if (newScale < 0.1) {
      newScale = 0.1;
    }

    setScale(newScale);
  };

  const onPage = (move) => {
    var newPage = page + move;
    if (newPage < 1 || newPage > pages) {
      return;
    }
    setPage(newPage);
  };

  const zoomStyle = {
    marginLeft: 10,
    cursor: "pointer",
    padding: "4px",
  };

  const footer = (
    <div className="footer flex justify-between">
      <Space>
        <Button onClick={() => onPage(-1)} disabled={page === 1}>
          Predošlá
        </Button>
        <Button onClick={() => onPage(1)} disabled={page === pages}>
          Ďalšia
        </Button>
      </Space>

      <div>
        <span style={{ textAlign: "center" }}>
          Page {page} of {pages}
        </span>
        <ZoomOutOutlined
          style={{ ...zoomStyle, opacity: scale === 0.1 ? 0.5 : 1 }}
          onClick={() => onSetScale(0)}
        />
        <ZoomInOutlined
          style={{ ...zoomStyle, opacity: scale === 2 ? 0.5 : 1 }}
          onClick={() => onSetScale(1)}
        />
        <span>{Math.round(scale * 100)}%</span>
      </div>
    </div>
  );

  useHotkeys("right", () => onPage(1), { keyup: true }, [onPage]);
  useHotkeys("left", () => onPage(-1), { keyup: true }, [onPage]);

  const width = useBreakpointValue({
    base: "100%",
    md: "80%",
    lg: "70%",
  });

  return (
    <Modal
      visible={visible}
      width={width}
      bodyStyle={{ overflow: "scroll", maxHeight: "88vh" }}
      style={{ top: 20 }}
      footer={footer}
      closable={true}
      onCancel={onCancel}
      maskClosable={true}
    >
      <div className="pdfWrapper">
        <SizeMe>
          {({ size }) => (
            <Document
              file={pdf}
              onLoadSuccess={(document) => {
                setPages(document.numPages);
              }}
            >
              <Page width={size.width} pageNumber={page} scale={scale} />
            </Document>
          )}
        </SizeMe>
      </div>
    </Modal>
  );
};
