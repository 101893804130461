import {
  FILTER_DEFAULTS,
  PAGINATION_DEFAULTS,
  SORTER_DEFAULT,
} from "./../../utils/paginationDefaults";
import { QueryAndQueryPrefetchParams } from "./../model/queryAndPrefetchParams";

export const queryAndPrefetchDefaults: QueryAndQueryPrefetchParams<any> = {
  paginationDefaults: PAGINATION_DEFAULTS,
  filterDefaults: FILTER_DEFAULTS,
  sorterDefault: SORTER_DEFAULT,
  queryOptions: {},
};
