import { graphqlApi } from "@/lib/api/graphqlApi";
import { CollectionQueryInput } from "@/lib/features/shared/model/collection-query-input.model";
import {
  CreateProjectMutation,
  DeleteProjectMutation,
  UpdateProjectMutation,
} from "../graphql/project.mutations";
import {
  GET_PROJECTS_QUERY,
  GET_SINGLE_PROJECT_QUERY,
} from "../graphql/project.queries";
import {
  CreateProjectInput,
  CreateProjectReturn,
} from "../model/actions/project-create.model";
import {
  DeleteProjectInput,
  DeleteProjectReturn,
} from "../model/actions/project-delete.model";
import {
  GetProjectCollectionReturn,
  GetSingleProjectReturn,
} from "../model/actions/project-read.model";
import {
  UpdateProjectInput,
  UpdateProjectReturn,
} from "../model/actions/project-update.model";
import { Project } from "../model/project.model";

/** Api method to create a project */
export const createProject = async (
  payload: CreateProjectInput
): Promise<Project> => {
  const {
    data: {
      createProject: { project },
    },
  } = await graphqlApi<CreateProjectReturn>(CreateProjectMutation, {
    variables: {
      input: payload,
    },
  });

  return project;
};

/** Api method to fetch a single project */
export const getSingleProject = async (id: number): Promise<Project> => {
  const {
    data: { project },
  } = await graphqlApi<GetSingleProjectReturn>(GET_SINGLE_PROJECT_QUERY, {
    variables: {
      id,
    },
  });

  return project;
};

/** Api method to fetch multiple projects in a collection with all the filters specified in queryInput applied */
export const getProjectCollection = async (
  queryInput: CollectionQueryInput
) => {
  const { data } = await graphqlApi<GetProjectCollectionReturn>(
    GET_PROJECTS_QUERY,
    {
      variables: queryInput,
    }
  );

  return data;
};

/** Api method to update project */
export const updateProject = async (
  updatePayload: UpdateProjectInput
): Promise<Project> => {
  const {
    data: {
      updateProject: { project },
    },
  } = await graphqlApi<UpdateProjectReturn>(UpdateProjectMutation, {
    variables: {
      input: updatePayload,
    },
  });

  return project;
};

/** Api method to delete project */
export const deleteProject = async (
  deleteInput: DeleteProjectInput
): Promise<Project> => {
  const {
    data: {
      deleteProject: { project },
    },
  } = await graphqlApi<DeleteProjectReturn>(DeleteProjectMutation, {
    variables: {
      input: deleteInput,
    },
  });

  return project;
};
