import { gql } from "graphql-tag";
import { projectFragment } from "./project.fragment";

// Query to retrieve single project
export const GET_SINGLE_PROJECT_QUERY = gql`
	${projectFragment}
	query getSingleProjectQuery($id: ID!) {
		project(id: $id) {
			...projectFragment
		}
	}
`;

// Query to retrieve multiple projects
export const GET_PROJECTS_QUERY = gql`
	${projectFragment}
	query getProjectsQuery(
		$sort: String = "id:desc"
		$limit: Int
		$start: Int
		$where: JSON
	) {
		projects(sort: $sort, limit: $limit, start: $start, where: $where) {
			...projectFragment
		}

		projectsCount(where: $where)
	}
`;
