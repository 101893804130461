import { gql } from "graphql-tag";
import { s3FileFragment } from "./s3-file.fragment";

// Query to retrieve single s3File
export const GET_SINGLE_S3_FILE_QUERY = gql`
	${s3FileFragment}
	query getSingleS3FileQuery($id: ID!) {
		s3File(id: $id) {
			...s3FileFragment
		}
	}
`;

// Query to retrieve multiple s3_files
export const GET_S3_FILES_QUERY = gql`
	${s3FileFragment}
	query getS3FilesQuery(
		$sort: String = "id:desc"
		$limit: Int
		$start: Int
		$where: JSON
	) {
		s3Files(sort: $sort, limit: $limit, start: $start, where: $where) {
			...s3FileFragment
		}

		s3FilesConnection(where: $where) {
			aggregate {
				count
			}
		}
	}
`;
