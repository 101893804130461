import { queryClient } from "@/lib/react-query/client";
import { useQuery } from "react-query";
import { downloadS3File } from "../api/s3-file.api";
import { USE_DOWNLOAD_S3_FILE_QUERY_KEY } from "../consts/s3-file.query-keys";

/**
 * Gets the contents of a file
 * @param file
 * @returns
 */
export const useGetAttachment = (file: any, options?: any) => {
  const id = file.id || file.response?.id;

  return useQuery(
    [USE_DOWNLOAD_S3_FILE_QUERY_KEY, id],
    async () => {
      const { data } = await downloadS3File(id);
      return data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      staleTime: 10000,
      ...options,
    }
  );
};

export const prefetchGetAttachmentQuery = async (attachmentId: number) => {
  await queryClient.prefetchQuery(
    [USE_DOWNLOAD_S3_FILE_QUERY_KEY, attachmentId],
    async () => {
      const { data } = await downloadS3File(attachmentId);
      return data;
    },
    {
      staleTime: 10000,
    }
  );
};
