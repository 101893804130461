import { usePagination } from "@/lib/features/shared/hooks/usePagination";
import { antToStrapiSorterMap } from "@/lib/features/shared/utils/antToStrapiSorterMap.util";
import { transformTableFilters } from "@/lib/features/shared/utils/transformTableFilters.util";
import { queryClient } from "@/lib/react-query/client";
import { queryAndPrefetchDefaults } from "@/lib/shared/consts/queryAndPrefetchDefaults";
import { QueryAndQueryPrefetchParams } from "@/lib/shared/model/queryAndPrefetchParams";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { getProjectCollection } from "../api/project.api";
import { USE_PROJECT_COLLECTION_QUERY_KEY } from "../consts/project.query-keys";
import { GetProjectCollectionReturn } from "./../model/actions/project-read.model";

export const prefetchProjectCollectionQuery = async ({
  sorterDefault = queryAndPrefetchDefaults.sorterDefault,
  paginationDefaults = queryAndPrefetchDefaults.paginationDefaults,
  filterDefaults = queryAndPrefetchDefaults.filterDefaults,
  queryOptions = queryAndPrefetchDefaults.queryOptions,
}: QueryAndQueryPrefetchParams<GetProjectCollectionReturn> = queryAndPrefetchDefaults) => {
  await queryClient.prefetchQuery(
    [
      USE_PROJECT_COLLECTION_QUERY_KEY,
      paginationDefaults,
      filterDefaults,
      sorterDefault,
    ],
    () =>
      getProjectCollection({
        limit: paginationDefaults.pageSize,
        start: 0,
        where: filterDefaults,
        sort: sorterDefault,
      })
  );
};
export const useProjectCollectionQuery = ({
  sorterDefault = queryAndPrefetchDefaults.sorterDefault,
  paginationDefaults = queryAndPrefetchDefaults.paginationDefaults,
  filterDefaults = queryAndPrefetchDefaults.filterDefaults,
  queryOptions = queryAndPrefetchDefaults.queryOptions,
}: QueryAndQueryPrefetchParams<GetProjectCollectionReturn> = queryAndPrefetchDefaults) => {
  const { pagination, setPagination } = usePagination(paginationDefaults);
  const [filters, setFilters] = useState(filterDefaults);
  const [sorter, setSorter] = useState(sorterDefault);

  const queryResult = useQuery(
    [USE_PROJECT_COLLECTION_QUERY_KEY, pagination, filters, sorter],
    () =>
      getProjectCollection({
        limit: pagination.pageSize,
        sort: sorter,
        start:
          pagination.current === 1
            ? 0
            : (pagination.current - 1) * pagination.pageSize,
        where: filters,
      }),
    {
      keepPreviousData: true,
      staleTime: 10000,
      ...queryOptions,
    }
  );

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    setPagination(pagination);
    if (sorter?.order) {
      setSorter(`${sorter.columnKey}:${antToStrapiSorterMap[sorter.order]}`);
    }
    setFilters(transformTableFilters(filters));
  }, []);

  return {
    ...queryResult,
    handleTableChange,
    pagination,
    setFilters,
  };
};
