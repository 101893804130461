import { gql } from "graphql-tag";
import { projectFragment } from "./project.fragment";

// Mutation to create project
export const CreateProjectMutation = gql`
	${projectFragment}
	mutation createProjectMutation($input: createProjectInput) {
		createProject(input: $input) {
			project {
				...projectFragment
			}
		}
	}
`;

// Mutation to update project
export const UpdateProjectMutation = gql`
	${projectFragment}
	mutation updateProjectMutation($input: updateProjectInput) {
		updateProject(input: $input) {
			project {
				...projectFragment
			}
		}
	}
`;

// Mutation to delete project
export const DeleteProjectMutation = gql`
	${projectFragment}
	mutation deleteProjectMutation($input: deleteProjectInput) {
		deleteProject(input: $input) {
			project {
				...projectFragment
			}
		}
	}
`;
