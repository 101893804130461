import { ApprovalStep } from "./../../approval-steps/model/approval-step.model";
export interface Invoice {
  id: number;
  dateReceived: Date;
  invoiceNumber: string;
  subject: string;
  pairingSymbol: string;
  attachments: InvoiceAttachment;
  dueDate: Date;
  project: InvoiceProject;
  department: InvoiceDepartment;
  partner: InvoicePartner;
  sum: number;
  approval_steps: ApprovalStep[];
  approvalProcessStatus: InvoiceApprovalProcessStatus;

  /**
   * Received mail from which the invoice has been generated
   */
  origin?: InvoiceOrigin;
}

export interface InvoiceApprovalStep {
  id: number;
  label: string;
  approved: boolean;
  created_at: Date;
}
export interface InvoiceOrigin {
  id: string;
}

export interface InvoiceDepartment {
  id: string;
  name: string;
}

export interface InvoicePartner {
  id: string;
  name: string;
}

export interface InvoiceAttachment {
  id: number;
}

export interface InvoiceProject {
  id: number;
  name: string;
}

export enum InvoiceApprovalProcessStatus {
  NotStartedYet = "notStartedYet",
  InProgress = "inProgress",
  Finished = "finished",
  FinishedRejected = "finishedRejected",
  DisputeResolution = "disputeResolution",
}
