import { queryClient } from "@/lib/react-query/client";
import { useQuery, UseQueryOptions } from "react-query";
import { getSingleProject } from "../api/project.api";
import { USE_SINGLE_PROJECT_QUERY_KEY } from "../consts/project.query-keys";
import { Project } from "../model/project.model";

export const prefetchSingleProjectQuery = async (id: number) => {
  await queryClient.prefetchQuery(
    [
      USE_SINGLE_PROJECT_QUERY_KEY,
      { id: typeof id === "number" ? id : parseInt(id) },
    ],
    () => getSingleProject(id)
  );
};
export const useSingleProjectQuery = (
  id: number,
  options: UseQueryOptions<Project>
) => {
  return useQuery<Project>(
    [
      USE_SINGLE_PROJECT_QUERY_KEY,
      { id: typeof id === "number" ? id : parseInt(id) },
    ],
    () => getSingleProject(id),
    {
      ...options,
      enabled: !!id,
    }
  );
};
