import { queryClient } from "@/lib/react-query/client";
import { ModalFormProps } from "@ant-design/pro-form";
import { message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { USE_PROJECT_COLLECTION_QUERY_KEY } from "../../../consts/project.query-keys";
import { useCreateProjectMutation } from "../../../hooks/useCreateProjectMutation.hook";
import { ProjectModalForm } from "../ProjectModalForm/ProjectModalForm";

export interface AddProjectModalFormProps extends ModalFormProps {
  className?: string;
  onProjectCreated?: (project: any) => void;
}

export const AddProjectModalForm = (props: AddProjectModalFormProps) => {
  const { className = "", onProjectCreated } = props;
  const [form] = useForm();
  const { mutateAsync } = useCreateProjectMutation({
    onSuccess: async (data: any) => {
      await queryClient.refetchQueries({
        queryKey: USE_PROJECT_COLLECTION_QUERY_KEY,
      });
    },
  });

  return (
    <ProjectModalForm
      form={form}
      title="Pridať zákazku"
      onFinish={async (values) => {
        // Create the project
        const project = await mutateAsync({
          data: values,
        });

        // Reset the form for the next address
        message.info("Zákazka úspešne pridaná");

        queryClient.invalidateQueries(USE_PROJECT_COLLECTION_QUERY_KEY);

        if (onProjectCreated) {
          onProjectCreated(project);
        }

        return true;
      }}
      {...props}
    />
  );
};
