import { gql } from "graphql-tag";

export const projectFragment = gql`
  fragment projectFragment on Project {
    id
    projectNumber
    invoiceMaxSumAllowedForProjectManager
    name
    partner {
      id
      name
    }
    managers {
      id
      name
    }
    mainManager {
      id
      name
    }
    description
    invoices {
      id
    }
    department {
      id
      name
    }
  }
`;
