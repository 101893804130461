import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LineOutlined,
  LoadingOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import React from "react";
import { InvoiceApprovalProcessStatus } from "../../../model/invoice.model";

export const approvalStatusIconMap: Record<
  InvoiceApprovalProcessStatus,
  JSX.Element
> = {
  [InvoiceApprovalProcessStatus.NotStartedYet]: <LineOutlined />,
  [InvoiceApprovalProcessStatus.InProgress]: (
    <Box color="gray.400" d="flex" alignItems="center">
      <LoadingOutlined />
    </Box>
  ),
  [InvoiceApprovalProcessStatus.Finished]: (
    <Box color="primary.500" d="flex" alignItems="center">
      <CheckCircleOutlined />
    </Box>
  ),
  [InvoiceApprovalProcessStatus.FinishedRejected]: (
    <Box color="red.400" d="flex" alignItems="center">
      <CloseCircleOutlined />
    </Box>
  ),
  [InvoiceApprovalProcessStatus.DisputeResolution]: (
    <Box color="blue.500" d="flex" alignItems="center">
      <SolutionOutlined />
    </Box>
  ),
};
