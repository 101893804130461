import { api, graphqlApi } from "@/lib/api/graphqlApi";
import { CollectionQueryInput } from "@/lib/features/shared/model/collection-query-input.model";
import {
  CreateS3FileMutation,
  DeleteS3FileMutation,
  UpdateS3FileMutation,
} from "../graphql/s3-file.mutations";
import {
  GET_S3_FILES_QUERY,
  GET_SINGLE_S3_FILE_QUERY,
} from "../graphql/s3-file.queries";
import {
  CreateS3FileInput,
  CreateS3FileReturn,
} from "../model/actions/s3-file-create.model";
import {
  DeleteS3FileInput,
  DeleteS3FileReturn,
} from "../model/actions/s3-file-delete.model";
import {
  GetS3FileCollectionReturn,
  GetSingleS3FileReturn,
} from "../model/actions/s3-file-read.model";
import {
  UpdateS3FileInput,
  UpdateS3FileReturn,
} from "../model/actions/s3-file-update.model";
import { S3File } from "../model/s3-file.model";

/** Api method to create a s3file */
export const createS3File = async (
  payload: CreateS3FileInput
): Promise<S3File> => {
  const {
    data: {
      createS3File: { s3File },
    },
  } = await graphqlApi<CreateS3FileReturn>(CreateS3FileMutation, {
    variables: {
      input: payload,
    },
  });

  return s3File;
};

export const createS3FileRest = async (payload: FormData): Promise<S3File> => {
  const { data } = await api({
    method: "POST",
    url: `/s-3-files`,
    data: payload,
  });

  return data;
};

/** Api method to fetch a single s3file */
export const getSingleS3File = async (id: number): Promise<S3File> => {
  const {
    data: { s3File },
  } = await graphqlApi<GetSingleS3FileReturn>(GET_SINGLE_S3_FILE_QUERY, {
    variables: {
      id,
    },
  });

  return s3File;
};

/** Api method to fetch multiple s3_files in a collection with all the filters specified in queryInput applied */
export const getS3FileCollection = async (queryInput: CollectionQueryInput) => {
  const { data } = await graphqlApi<GetS3FileCollectionReturn>(
    GET_S3_FILES_QUERY,
    {
      variables: queryInput,
    }
  );
  return data;
};

/** Api method to update s3file */
export const updateS3File = async (
  updatePayload: UpdateS3FileInput
): Promise<S3File> => {
  const {
    data: {
      updateS3File: { s3File },
    },
  } = await graphqlApi<UpdateS3FileReturn>(UpdateS3FileMutation, {
    variables: {
      input: updatePayload,
    },
  });

  return s3File;
};

/** Api method to delete s3file */
export const deleteS3File = async (
  deleteInput: DeleteS3FileInput
): Promise<S3File> => {
  const {
    data: {
      deleteS3File: { s3File },
    },
  } = await graphqlApi<DeleteS3FileReturn>(DeleteS3FileMutation, {
    variables: {
      input: deleteInput,
    },
  });

  return s3File;
};

/**
 * API method to get the contents of a file
 * @param id
 * @returns
 */
export const downloadS3File = async (id: number) => {
  return await api({
    method: "GET",
    responseType: "blob", // Important
    url: `/s-3-files/download/${id}`,
  });
};
