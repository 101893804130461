import { EditApprovalStepInput } from "@/lib/sdk/sdk";
import { CheckOutlined } from "@ant-design/icons";
import { ModalForm, ProFormTextArea } from "@ant-design/pro-form";
import { Button, ButtonProps } from "antd";
import React from "react";

export interface ApproveDocumentModalProps {
  className?: string;
  buttonProps?: ButtonProps;
  onFinish: (values: EditApprovalStepInput) => Promise<boolean>;
}

export const ApproveDocumentModal = (props: ApproveDocumentModalProps) => {
  const { className = "", onFinish, buttonProps } = props;
  return (
    <ModalForm<EditApprovalStepInput>
      title="Schváliť faktúru"
      width={600}
      trigger={
        <Button icon={<CheckOutlined />} type="primary" {...buttonProps}>
          Schváliť
        </Button>
      }
      modalProps={{}}
      onFinish={onFinish}
      submitter={{
        submitButtonProps: {
          icon: <CheckOutlined />,
        },
        searchConfig: {
          submitText: "Schváliť",
          resetText: "Zrušiť",
        },
      }}
    >
      <ProFormTextArea
        name="note"
        label="Poznámka"
        tooltip="Poznámka k schválení faktúry."
        placeholder=""
      />
    </ModalForm>
  );
};
