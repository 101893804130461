import { gql } from "graphql-tag";

export const s3FileFragment = gql`
	fragment s3FileFragment on S3File {
		id
		name
		key
		type
		uploadedAt
	}
`;
